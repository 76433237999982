/*!
 * Copyright 2024 - Swiss Data Science Center (SDSC)
 * A partnership between École Polytechnique Fédérale de Lausanne (EPFL) and
 * Eidgenössische Technische Hochschule Zürich (ETHZ).
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License
 */

import { useEffect } from "react";

import useAppDispatch from "../customHooks/useAppDispatch.hook";
import { FEATURE_FLAG_LOCAL_STORAGE_KEY_PREFIX } from "./featureFlags.constants";
import { reset } from "./featureFlags.slice";

export default function useFeatureFlagSync() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    function listener(event: StorageEvent) {
      if (event.key == null) {
        dispatch(reset());
        return;
      }
      if (!event.key.startsWith(FEATURE_FLAG_LOCAL_STORAGE_KEY_PREFIX)) {
        return;
      }

      dispatch(reset());
    }

    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [dispatch]);
}
