/* eslint-disable */
export const user = {
  id: 1,
  name: "Test User",
  username: "tuser",
  state: "active",
  avatar_url: "",
  web_url: "https://renkulab.io/gitlab/tuser",
  created_at: "2018-06-19T10:11:04.058Z",
  bio: null,
  location: null,
  public_email: "",
  skype: "",
  linkedin: "",
  twitter: "",
  website_url: "",
  organization: null,
  last_sign_in_at: "2019-01-04T11:11:00.509Z",
  confirmed_at: "2018-06-19T10:11:04.027Z",
  last_activity_on: "2018-12-19",
  email: "tuser@dummy.com",
  theme_id: 1,
  color_scheme_id: 1,
  projects_limit: 100000,
  current_sign_in_at: "2019-01-04T12:47:31.611Z",
  identities: [
    {
      provider: "oauth2_generic",
      extern_uid: "aaaabbbbccceeee",
    },
  ],
  can_create_group: true,
  can_create_project: true,
  two_factor_enabled: false,
  external: false,
  private_profile: null,
};
