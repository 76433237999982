/*!
 * Copyright 2024 - Swiss Data Science Center (SDSC)
 * A partnership between École Polytechnique Fédérale de Lausanne (EPFL) and
 * Eidgenössische Technische Hochschule Zürich (ETHZ).
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License
 */

import { startCase } from "lodash-es";

import { toHumanDateTime } from "../../../utils/helpers/DateTimeUtils";
import type {
  IncidentUpdate,
  IncidentUpdates,
} from "../statuspage-api/statuspage.types";

interface StatusPageIncidentUpdatesProps {
  incidentUpdates: IncidentUpdates;
}

export default function StatusPageIncidentUpdates({
  incidentUpdates,
}: StatusPageIncidentUpdatesProps) {
  return (
    <>
      {incidentUpdates.map((update) => (
        <StatusPageIncidentUpdate key={update.id} update={update} />
      ))}
    </>
  );
}

interface StatusPageIncidentUpdateProps {
  update: IncidentUpdate;
}

function StatusPageIncidentUpdate({ update }: StatusPageIncidentUpdateProps) {
  const { body, display_at, id, status } = update;
  const title = startCase(status);

  return (
    <p key={id} className="mb-1">
      <span className="fw-bold">{title}</span> - {body}{" "}
      <span className="time-caption" style={{ fontSize: "smaller" }}>
        Posted at{" "}
        {toHumanDateTime({
          datetime: display_at,
          format: "full",
        })}
      </span>
    </p>
  );
}
